import './bootstrap';
import '../styles/app.scss';

import {createApp, DefineComponent, h} from "vue";
import {createInertiaApp} from '@inertiajs/vue3';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {ZiggyVue} from '../../../vendor/tightenco/ziggy/dist/vue.m';
import 'vuetify/styles';
import {createVuetify} from "vuetify";
import {VDataTable, VDataTableServer} from 'vuetify/components';
import {VDatePicker} from 'vuetify/components';
import draggable from 'vuedraggable';
import {aliases, fa} from 'vuetify/iconsets/fa';
import {md3} from "vuetify/blueprints";
import {fr} from "vuetify/locale";
import {i18nVue} from "laravel-vue-i18n";
import {createPinia} from "pinia";

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

const pinia = createPinia();

const vuetify = createVuetify({
    components: {
        VDataTable,
        VDataTableServer,
        VDatePicker,
        draggable
    },
    icons: {
        defaultSet: 'fa',
        aliases,
        sets: {
            fa,
        },
    },
    blueprint: md3,
    locale: {
        locale: 'fr',
        messages: {fr}
    },
    theme: {
        defaultTheme: 'light',
        themes: {
            light: {
                colors: {
                    // surface: 'rgb(255, 250, 254)'
                }
            }
        }
    },
    defaults: {
        VTextField: {
            variant: 'outlined',
            hideDetails: 'auto',
            class: 'mt-8'
        },
        VTextarea: {
            variant: 'outlined',
            hideDetails: 'auto',
            class: 'mt-8'
        },
        VFileInput: {
            variant: 'outlined',
            hideDetails: 'auto',
            class: 'mt-8'
        },
        VAutocomplete: {
            variant: 'outlined',
            hideDetails: 'auto',
            class: 'mt-8'
        },
        VSelect: {
            variant: 'outlined',
            hideDetails: 'auto',
            class: 'mt-8'
        },
        VRadioGroup: {
            variant: 'outlined',
            class: 'mt-8',
        },
    }
});

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob<DefineComponent>('./Pages/**/*.vue')),
    setup({el, App, props, plugin}) {
        const app = createApp({render: () => h(App, props)});

        app.use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(vuetify)
            .use(i18nVue, {
                resolve: async (lang: string) => {
                    const langs = import.meta.glob('../../../lang/*.json');
                    return await langs[`../../../lang/${lang}.json`]();
                }
            })
            .use(pinia)
            .mount(el);
    }
});
